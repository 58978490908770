import { ACPVariant } from 'apps/acp/variants/acp-variant';

const colors = {
  neutral: {
    color50: '#f7f9f9',
    color100: '#f0f3f3',
    color200: '#e1e7e8',
    color300: '#d9e1e2',
    color400: '#b8bdbf',
    color500: '#97999b',
    color600: '#75787b',
    color700: '#53565a',
    color800: '#37393c',
    color900: '#1c1d1e'
  },
  default: {
    color50: '#dddddd',
    color100: '#d6d6d6',
    color200: '#adadad',
    color300: '#838483',
    color400: '#5a5b5a',
    color500: '#313231',
    color600: '#272827',
    color700: '#1d1e1d',
    color800: '#141414',
    color900: '#0a0a0a'
  },
  accent: {
    color50: '#fcdddf',
    color100: '#fbd6d8',
    color200: '#f7acb1',
    color300: '#f4838a',
    color400: '#f05963',
    color500: '#ec303c',
    color600: '#bd2630',
    color700: '#8e1d24',
    color800: '#5e1318',
    color900: '#2f0a0c'
  },
  positive: {
    color50: '#dfeede',
    color100: '#c0dcbd',
    color200: '#a0cb9c',
    color300: '#80b97b',
    color400: '#61a85a',
    color500: '#419639',
    color600: '#34782e',
    color700: '#275a22',
    color800: '#1a3c17',
    color900: '#0d1e0b'
  },
  negative: {
    color50: '#fcdddf',
    color100: '#fbd6d8',
    color200: '#f7acb1',
    color300: '#f4838a',
    color400: '#f05963',
    color500: '#ec303c',
    color600: '#bd2630',
    color700: '#8e1d24',
    color800: '#5e1318',
    color900: '#2f0a0c'
  },
  special: {
    color50: '#d5edf4',
    color100: '#aadbe9',
    color200: '#80c9de',
    color300: '#55b6d2',
    color400: '#2ba4c7',
    color500: '#0092bc',
    color600: '#007596',
    color700: '#005871',
    color800: '#003a4b',
    color900: '#001d26'
  }
};

const variant: ACPVariant = {
  id: 'www.gianteagleprepaid.com',
  productTitle: 'Giant Eagle Prepaid Account',
  productType: 'gpr',
  accessDomain: {
    subdomain: 'www',
    domain: 'gianteagleprepaid',
    topLevelDomain: 'com'
  },
  colors,
  legacyAcpTheme: 'gianteagle',
  legacyAcpBrandName: 'gianteagle',
  legacyLegosTheme: 'gianteagle',
  status_bar_background_color: colors.default.color500,
  status_bar_foreground_color: 'light',
  themeConfiguration: {
    acpLoginTheme: {
      overrideLinkColor: colors.special.color500
    },
    acpRecoverTheme: {
      overrideLinkColor: colors.special.color500
    },
    acpDynamicFaqTheme: {
      overrideLinkColor: colors.special.color500
    },
    acpEsignTheme: {
      overrideLinkColor: colors.special.color500
    },
    acpSecureUploadTheme: {
      overrideLinkColor: colors.special.color500
    },
    acpUnAuthLayout: {
      overrideLinkColor: colors.special.color500
    },
    acpHandOffTheme: {
      overrideLinkColor: colors.special.color500
    }
  },
  contactInfo: {
    name: 'Giant Eagle fuelperks! Netspend Visa Prepaid Card',
    contact_address_line1: 'PO Box 2136',
    contact_address_line2: 'Austin, TX 78768-2136',
    contact_address_line3: '',
    customer_support_email: 'customerservice@netspend.com',
    fax_formatted: '(512) 857-0263',
    phone_formatted: '(866) 615-5224',
    phone: '8666155224',
    phone_international: '737-220-8956',
    balance_check_phone: '8663877363',
    balance_check_phone_formatted: '1-866-387-7363',
    pre_funded_checks_phone: '18778147683',
    pre_funded_checks_phone_formatted: '1-877-814-7683',
    externalLoyaltyIdPattern: '^([0-9]{12})$',
    externalLoyaltyGlyph: 'fuelperks',
    address_line_1: 'PO Box 2136',
    address_line_2: 'Austin, TX 78768-2136'
  },
  configuration: {
    apiKeys: {
      appsFlyerDevKey: '5Us7NeWk6WVjGLsAnKioSk',
      appsFlyerBannerKey: '08413cff-906a-4879-96ce-b374b7767901',
      'google-tag-manager-container-id': 'GTM-NQXKPD',
      'google-tag-manager-container-id-mobile': 'GTM-MWBTT5M',
      paypalFraudNetPayerIdProd: '',
      paypalFraudNetPayerIdTest: ''
    },
    featureToggles: {
      activationSetExternalId: true,
      appsFlyerEnabled: false,
      combinedTransactionsMasterAndSub: false,
      'embedded-activation': true,
      embeddedPayNearMe: false,
      hasNotificationCenter: false,
      mobilePBREnabled: false,
      preOnboardingEnabled: false,
      showAccountDisplayNickname: false,
      showAcquisitionLink: true,
      showATMFriendlyCards: true,
      showDisplayNameForSubAccount: false,
      showEmailInformationButton: true,
      showFeePlanPage: false,
      showHowToEarnRewardPointLink: true,
      showRegisterLink: true,
      showScanCardRegistration: true,
      showMarketingSiteLink: true,
      showFreeAtmFinderLink: false,
      showWUTransfers: false,
      showReloadLocationLink: true
    },
    misc: {
      layoutComponentOverride: false,
      loader: 'generic',
      storeNameOverride: 'Safeway',
      payNearMeButtonTextColor: '#FFFFFF',
      payNearMeActionBarBackgroundColor: '#313231',
      payNearMeTitleTextColor: '#FFFFFF',
      payNearMeColorAccent: '#419639',
      externalLoyaltyProgramName: 'FuelPerks',
      externalLoyaltyProgramIcon: 'fuelperks',
      marketingSiteUrl: 'https://www.gianteagleprepaid.com',
      marketingSiteLinkText: 'Gianteagleprepaid.com',
      locationSearchMax: '25',
      iOSAppId: '1147224862'
    },
    content: {
      esignProductNameContent: 'fuelperks! Netspend Card'
    },
    disclaimers: {},
    sections: [
      'dashboard-gpr',
      'dashboard-lite',
      'dashboard-handoff',
      'layout',
      'manage-gpr',
      'move-money',
      'overdraft',
      'locations',
      'direct-deposit',
      'direct-deposit-mobile-panel',
      'statements',
      'contact-us',
      'restrictions-readonly',
      'feeplan',
      'western-union',
      'disclosure-dictionary',
      'check-deposit',
      'checks',
      'quickbooks-oauth',
      'points',
      'post-handoff-to-activate-register',
      'upgrade',
      'offers',
      'offers-intro',
      'tours',
      'post-install-multi-screen-acquisition-flow',
      'echecks',
      'external-loyalty',
      'secure-inbox',
      'benefit-center',
      'atm-finder',
      'savings',
      'pay-bills',
      'premier-plan',
      'activation',
      'savings-transfer',
      'anytime-alerts',
      'anytime-alerts-upgrade',
      'change-username',
      'change-password',
      'mobile-check-load',
      'feedback',
      'rent-track',
      'direct-deposit-form',
      'dynamic-faq',
      'billpay',
      'card',
      'refer-a-friend',
      'close-account',
      'points-learn-more',
      'family-cards',
      'bank-transfers',
      'handoff',
      'debit-card-transfers',
      'card-use-settings',
      'secure-upload',
      'digital-wallet',
      'me-to-me-transfer',
      'money-gram',
      'survey',
      'debit-card-transfers-disbursement',
      'spend-money',
      'fraud-detection',
      'spending-tracker',
      'connected-banks',
      'interstitials',
      'dd-tracker'
    ]
  }
};

export default variant;
